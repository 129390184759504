import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Head } from '../stories/Head';
import S_Profile from '../NewComponent/Skeletons/S_Profile';
import ProfileBox from '../NewComponent/ProfileBox';
import BottomNav from '../NewComponent/BottomNav';
import { motion } from 'framer-motion';
import soap_bubble from '../NewComponent/assets/soap-bubble 2.webp';
import profile_loading from '../NewComponent/assets/Loading.gif';
import ClipLoader from 'react-spinners/ClipLoader'; // 로딩 스피너 import
import Modal from 'react-modal'; // 모달 import
import axios from 'axios'; // axios import
import vote_ballot from '../NewComponent/assets/chat_bubble.webp';
import art_img from '../NewComponent/assets/Art1.webp';
import microphone from '../assets/img/microphone.webp';
import message2 from '../assets/img/message2.webp';
import happy_friends_jumping from '../assets/img/happy-friends-jumping.webp';
import love_letter from '../NewComponent/assets/love_letter.webp';
import moment from 'moment-timezone'; // moment-timezone import

// 프로필 이미지 import
import rdod_img from '../assets/profile_img/rdod_profile.jpg';
import minting_img from '../assets/profile_img/minting_img.png';
import sucong_img from '../assets/profile_img/sucong_profile.jpg';
import { rect } from 'framer-motion/client';

const hardcodedUsers = {
  rdod2_05: {
    user_name: '최형빈',
    user_id: 'rdod2_05',
    school_name: '🐳',
    reciver_email: 'rdod205@gmail.com',
    user_insta_img: rdod_img,
    user_insta_followed: 950,
    user_insta_follow: 801
  },
  su_cong_2: {
    user_name: '수콩',
    user_id: 'su_cong_2',
    school_name: '🚀',
    reciver_email: 'win3137054@gmail.com',
    user_insta_img: sucong_img,
    user_insta_followed: 912,
    user_insta_follow: 681
  },
  minjeong2glong: {
    user_name: '민팅',
    user_id: 'minjeong2glong',
    school_name: '🐒',
    reciver_email: 'kmj2glong@gmail.com',
    user_insta_img: minting_img,
    user_insta_followed: 711,
    user_insta_follow: 1199
 }
};

const buttonStyle = {
  display: 'flex',
  width: '325px',
  padding: '15px 120px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '12px',
  background: '#FBF5F5',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  marginTop: '-10px'
};

const buttonTextStyle = {
  color: '#FF5C00',
  textAlign: 'center',
  fontFamily: 'Pretendard',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: 600,
  letterSpacing: '-0.68px',
  whiteSpace: 'nowrap'
};

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '400px',
    padding: '20px',
    borderRadius: '12px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Pretendard',
    border: 'none'
  }
};

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px'
};

const inputStyle = {
  padding: '10px',
  borderRadius: '8px',
  border: '1px solid #ddd',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  width: '100%',
  boxSizing: 'border-box'
};

const modalButtonStyle = {
  padding: '10px',
  borderRadius: '8px',
  border: 'none',
  backgroundColor: '#FF5C00',
  color: '#fff',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  cursor: 'pointer',
  width: '48%'
};

const closeButtonStyle = {
  padding: '10px',
  borderRadius: '8px',
  border: 'none',
  backgroundColor: '#ddd',
  color: '#333',
  fontFamily: 'Pretendard',
  fontSize: '16px',
  cursor: 'pointer',
  width: '48%'
};

function Profile({animate}) {
  const { user_id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [buttonAnimate, setButtonAnimate] = useState('initial');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // 제출 상태 추가
  const [appointmentType, setAppointmentType] = useState(''); // 약속 종류 상태 추가

  const openModal = (type) => {
    setAppointmentType(type); // 약속 종류 설정
    setIsModalOpen(true);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setButtonAnimate((prevAnimate) => (prevAnimate === 'highlight' ? 'initial' : 'highlight'));
    }, 600);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (user_id && hardcodedUsers[user_id]) {
      setUser(hardcodedUsers[user_id]);
      setIsLoading(false);
    } else {
      // 사용자가 존재하지 않으면 시작 페이지로 리다이렉트
      navigate('/');
    }
  }, [user_id, navigate]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // 제출 상태 변경
    const formData = new FormData(event.target);
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      reason: formData.get('reason'),
      datetime: moment(formData.get('datetime')).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss'), // KST로 변환
      appointmentType: appointmentType, // 약속 종류 추가
      user_name : user.user_name,
      user_id : user.user_id,
      reciver_email : user.reciver_email
    };
    
    try {
      // 백엔드 서버의 엔드포인트에 요청을 보냄
      await axios.post(
        'https://api.kkokk.io:4004/api/yagsog/send-slack-message', // 백엔드 엔드포인트
        data
      );
      alert('신청이 성공적으로 전송되었습니다.');
    } catch (error) {
      console.error('Error sending message to Slack:', error);
      alert('신청 전송에 실패했습니다.');
    }
    
    closeModal();
    setIsSubmitting(false); // 제출 상태 초기화
  };
  
  if (isLoading) {
    return (
      <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ClipLoader color="#FF5C00" size={50} />
      </div>
    );
  }

  return (
    <div className="main" style={{ backgroundColor: '#FFFFFF' }}>
      <div className="main_content" style={{ backgroundColor: '#FFFFFF' }}>
        <div>
          <br />
          <div style={{ marginTop: '5px' }}>
            <Head className="MainHead" />
          </div>
          <div style={{ marginTop: '20px' }}>
            <ProfileBox
              profile_name={user.user_name}
              profile_school={`${user.school_name}`}
              profile_insta={`@${user.user_id} ·`}
              profile_id={user.user_id}
              profile_follower={`팔로워 ${user.user_insta_followed}명 ·`}
              profile_following={`팔로잉 ${user.user_insta_follow}명`}
              img={user.user_insta_img || profile_loading}
              button_img='instagram'
            />
          </div>
          <div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <br />
            <div className="MessageTo" style={{ textAlign: 'left', marginLeft: '35px', whiteSpace: 'nowrap' }}>{user.user_name}과의 약속 메뉴판 🍀</div>
            <br />

            <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
              <motion.button
                style={buttonStyle}
                variants={{
                  initial: { scale: 1, x: 0, rotate: 0 },
                  highlight: {
                    scale: 1.05,
                    x: [0, -5, 5, -3, 3, 0],
                    rotate: [0, -5, 5, -3, 3, 0],
                    transition: { type: 'spring', stiffness: 300, damping: 10 },
                  }
                }}
                initial="initial"
                animate={animate === 'highlight' ? buttonAnimate : 'initial'}
                whileTap={{ scale: 0.95 }}
                onClick={() => openModal('일에 대한 커피챗 나누기')}              >
                <span style={buttonTextStyle}>일에 대한 커피챗 나누기</span>
                <img src={message2} width='22px' style={{ marginLeft: '5px', marginTop: '-2px' }} alt="soap bubble" />
              </motion.button>
            </div>

            <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
              <motion.button
                style={buttonStyle}
                variants={{
                  initial: { scale: 1, x: 0, rotate: 0 },
                  highlight: {
                    scale: 1.05,
                    x: [0, -5, 5, -3, 3, 0],
                    rotate: [0, -5, 5, -3, 3, 0],
                    transition: { type: 'spring', stiffness: 300, damping: 10 },
                  }
                }}
                initial="initial"
                animate={animate === 'highlight' ? buttonAnimate : 'initial'}
                whileTap={{ scale: 0.95 }}
                onClick={() => openModal('새로 나온 영화 같이 보기')}              >
                <span style={buttonTextStyle}>새로 나온 영화 같이 보기</span>
                <img src={soap_bubble} width='22px' style={{ marginLeft: '5px', marginTop: '-2px' }} alt="soap bubble" />
              </motion.button>
            </div>

            <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
              <motion.button
                style={buttonStyle}
                variants={{
                  initial: { scale: 1, x: 0, rotate: 0 },
                  highlight: {
                    scale: 1.05,
                    x: [0, -5, 5, -3, 3, 0],
                    rotate: [0, -5, 5, -3, 3, 0],
                    transition: { type: 'spring', stiffness: 300, damping: 10 },
                  }
                }}
                initial="initial"
                animate={animate === 'highlight' ? buttonAnimate : 'initial'}
                whileTap={{ scale: 0.95 }}
                onClick={() => openModal('진지한 대화와 알콜을 곁들이기')}              >
                <span style={buttonTextStyle}>진지한 대화와 알콜을 곁들이기</span>
                <img src={microphone} width='22px' style={{ marginLeft: '5px', marginTop: '-2px' }} alt="soap bubble" />
              </motion.button>
            </div>

            <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
              <motion.button
                style={buttonStyle}
                variants={{
                  initial: { scale: 1, x: 0, rotate: 0 },
                  highlight: {
                    scale: 1.05,
                    x: [0, -5, 5, -3, 3, 0],
                    rotate: [0, -5, 5, -3, 3, 0],
                    transition: { type: 'spring', stiffness: 300, damping: 10 },
                  }
                }}
                initial="initial"
                animate={animate === 'highlight' ? buttonAnimate : 'initial'}
                whileTap={{ scale: 0.95 }}
                onClick={() => openModal('인기 팝업 같이 가보기')}>
                <span style={buttonTextStyle}>인기 팝업 같이 가보기</span>
                <img src={art_img} width='22px' style={{ marginLeft: '5px', marginTop: '-2px' }} alt="soap bubble" />
              </motion.button>
            </div>

            <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
              <motion.button
                style={buttonStyle}
                variants={{
                  initial: { scale: 1, x: 0, rotate: 0 },
                  highlight: {
                    scale: 1.05,
                    x: [0, -5, 5, -3, 3, 0],
                    rotate: [0, -5, 5, -3, 3, 0],
                    transition: { type: 'spring', stiffness: 300, damping: 10 },
                  }
                }}
                initial="initial"
                animate={animate === 'highlight' ? buttonAnimate : 'initial'}
                whileTap={{ scale: 0.95 }}
                onClick={() => openModal('땀 좀 흘리며 같이 러닝하기')}>
              
                <span style={buttonTextStyle}>땀 좀 흘리며 같이 러닝하기</span>
                <img src={happy_friends_jumping} width='22px' style={{ marginLeft: '5px', marginTop: '-2px' }} alt="soap bubble" />
              </motion.button>
            </div>
          </div>
        </div>
        <BottomNav />
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyle}
        contentLabel="약속 신청"
      >
        <h2 style={{ fontFamily: 'Pretendard', textAlign: 'center' }}>{user.user_name}과의 약속 잡기</h2>
        <form onSubmit={handleSubmit} style={formStyle}>
          <div>
            <input type="text" name="name" required style={inputStyle} placeholder='신청자님의 이름'/>
          </div>
          <div>
            <input type="email" name="email" required style={inputStyle} placeholder='신청자님의 이메일 (For Google Calendar)' />
          </div>
          <div>
            <input type="tel" name="phone" required style={inputStyle} placeholder='신청자님의 전화번호' />
          </div>
          <div>
            <textarea name="reason" required style={{ ...inputStyle, height: '100px' }} placeholder='(선택 사항) 처음 만나는 사이라면, 자신을 소개하거나 약속을 신청한 이유를 알려주세요 '></textarea>
          </div>
          <div>
            <input type="datetime-local" name="datetime" required style={inputStyle} placeholder='원하는 날짜와 시간을 알려주세요' />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button type="submit" style={modalButtonStyle} disabled={isSubmitting}>
        {isSubmitting ? <ClipLoader color="#fff" size={15} /> : '신청'}
      </button>            
      <button type="button" onClick={closeModal} style={closeButtonStyle}>취소</button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default Profile;