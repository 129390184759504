import React, { useState } from "react";
import { ReactComponent as Home } from '../NewComponent/assets/icon-home-mono.svg';

const BottomNav = () => {
  const [activeNav, setActiveNav] = useState(1);

  const handlePopupButtonMain = () => {
    window.location.reload(); // 페이지 리프레시
  };

  return (
    <div>
      <nav className="wrapper" style={{border:'none', display: 'flex', justifyContent: 'center'}}>
        <div className="nav-link" onClick={() => { setActiveNav(1); handlePopupButtonMain(); }}>
          <Home 
            icon="home"
            style={{border:'none'}}
            className={activeNav === 1 ? "nav-item active" : "nav-item"}
          />
        </div>
      </nav>
    </div>
  );
};

export default BottomNav;