import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Profile from './Page/Profile';
import './App.css';

function App() {
  return (
    <Router>
      <div className='App'>
      <Routes>
      <Route path="/:user_id" element={<Profile />} />
      </Routes>
      </div>
    </Router>
  );
}

export default App;