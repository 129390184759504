import React from 'react';
import PropTypes from 'prop-types';
import './Head.css';
import '../component/Hamburger';
import Ham from '../component/Hamburger';
import Sea from '../component/SearchIcon';
import { Link } from 'react-router-dom';
import Arr from '../component/Arrow';
import jokercard from '../NewComponent/assets/joker_card.webp';
import { useNavigate } from 'react-router-dom';
import alarm from '../NewComponent/assets/Alarm.webp'

export const Head = ({ Label, Label2, Label3, Label4, className }) => {
  const HeadClassName = `${className}`;
  const VisitprofileID = localStorage.getItem('VisitprofileID');

  const navigate = useNavigate();

  const handleGoBack = () => {
    window.history.back();
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleHome = () => {
    navigate(`/${VisitprofileID}?explore=yes`);
  };


  return (
    <div className={HeadClassName + 'box'}>
      <div className={HeadClassName + 'Navigator-1'}>
      </div>
      <div className={HeadClassName + 'Navigator-2'}>
        <button className="invisible-button" onClick={handleGoBack}>
          <Arr></Arr>
        </button>
      </div>
      <div className={HeadClassName + 'margin1'}></div>
     
   <div className={HeadClassName + 'Navigator-3'} style={{marginLeft:'50px'}}>
       {/* 개발 전까지 주석처리 <Link to="/tab"> */}
           <img style={{width:'20px'}} src={alarm}></img>
        {/* 개발 전까지 주석처리 </Link>  */}
  </div>
    </div>
  );
};

Head.propTypes = {
  Label1: PropTypes.string.isRequired,
  Label2: PropTypes.string.isRequired,
  Label3: PropTypes.string.isRequired,
  Label4: PropTypes.string.isRequired,
  className: PropTypes.string,
};
